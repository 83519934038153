<template>
  <div class="login-form">
    <!-- Captcha -->
    <form class="form-group" @submit.prevent="verifySubmit">
        <!--
          <div v-if="formError" class="error-container">
        <p class="error">{{ formError }}</p>
      </div>
        -->

      <div v-if="isCaptchaEnabled && captchaImage">
        <label class="login-label">{{ $t("Please enter the numbers you see in the image") }}</label>
        <div
          v-if="captchaImage"
          :style="{
            backgroundImage: 'url(' + captchaImage + ')',
            'background-repeat': 'no-repeat',
            'background-color': 'white',
            width: '150px',
          }"
          class="captcha-image"
        ></div>
      </div>

      <div v-if="isCaptchaEnabled">
        <button @click="getAnotherCaptcha" class="button-link" type="button">
          {{ $t("Click to refresh if you can not read") }}
        </button>
      </div>
      <!--

      <div class="form-control" v-if="isCaptchaEnabled">
        <div class="label-container" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
        
        </div>
        <div class="input-container" dir="ltr">
          <input
            id="verifyCaptaha"
            type="text"
            v-model="verifyCaptaha"
           :placeholder="`${this.$t('The result')}`"
            @keydown.space.prevent
          />
        </div>
      </div>
    -->


        <JhakaasInput 
    v-if="isCaptchaEnabled"
        inputType="text"
        :label="`${this.$t('Enter captcha')}`"
        inputId="verifyCaptaha"
        reference="verifyCaptaha"
        :helperText="formError"
        :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"
        autocomplete="false"
        @keydown.space.prevent
        required
        :value="verifyCaptaha"
        @onChange="value => verifyCaptaha = value"
        />
       


      <br />
      <div class="login-button">
        <button class="button-primary login" type="submit">
          {{ $t("verify") }}
        </button>
      </div>
    </form>
    <div v-if="isloading">
      <Loading></Loading>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    formData: {
      type: Object,
    },
  },

  components: {
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
    JhakaasInput: () => import("../../utils/JhakaasInput.vue"),
  },

  watch: {
    formError(val) {
      if (val) {
        setTimeout(() => {
          this.formError = null;
        }, 5000);
      }
    },
  },

  computed: {
    ...mapGetters(["appConfig"]),
  },

  data() {
    return {
      captchaImage: true,
      isCaptchaEnabled: true,
      localDisplayLang: null,
      captchaRefId: null,
      verifyCaptaha: null,
      formError: null,
      isloading: false,
    };
  },

  created() {
    console.log("THE VERIFY EMAIL CAPTCHA -- ", this.formData);

    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    this.getCaptchaImage();
    // this.captchaPlaceholder = this.$t("The result");
    // eventBus.$on("resend-response", (response) => {
    //   if (this.formData.email) {
    //     if (response.reason) {
    //       this.formError = this.$t(response.reason);
    //     } else {
    //       let template = {
    //         //   input: this.userMobileno,
    //         formType: "lookup",
    //       };

    //       let snackbarPayload = {
    //         state: true,
    //         message: "Verification Email has been sent to your Email ID",
    //         color: "#ff9600",
    //       };

    //       eventBus.$emit("snackBarMessage", snackbarPayload);

    //       // snackbar.show({
    //       //   text: this.$t(response.reason),
    //       //   textColor: "#ffffff",
    //       //   pos: "bottom-center",
    //       //   actionTextColor: "#de2600",
    //       // });

    //       this.$emit("change", template);
    //     }
    //   }
    // });
  },

  methods: {
    ...mapActions(["getCaptcha", "resendLink"]),

    verifySubmit() {
      this.isloading = true;
      if (!this.verifyCaptaha) {
        this.formError = this.$t("Please enter Captcha");
        this.isloading = false;
        return;
      }

      const resendCaptcha = {};

      if (this.formData.email) {
        resendCaptcha.email = this.formData.email;
      } else if (this.formData.mobileno) {
        resendCaptcha.mobileno = this.formData.mobileno;
      }

      resendCaptcha.captcha = JSON.stringify({
        text: this.verifyCaptaha,
        referenceid: this.captchaRefId,
      });

      this.resendLink(resendCaptcha)
        .then((response) => {
          console.log("THE LOG FROM INSIDE THE COMPONENT ---> ", response, this.formData);
          if (response.data.errorcode) {
            this.formError = this.$t(response.data.reason);
            this.isloading = false;
          } else {
          console.log("THE LOG FROM INSIDE THE COMPONENT ---> 2",  this.formData);

            if (this.formData.input === 'email' && this.formData.email) {
              let template = {
                formType: "lookup",
              };

              this.$emit("change", template);
            } else if (this.formData.mobileno) {
              let template = {
                input: this.formData.mobileno,
                formType: "mobile",
                isResend: true,
              };
              this.$emit("change", template);
            }
            this.isloading = false;
          }
        })
        .catch((err) => {
          console.log("This is a resend Error -- > ", err);
          this.isloading = false;
        });
    },

    //   const newPayload = {
    //     ...this.formData.data.payload,
    //   };
    //   newPayload.params.captcha = JSON.stringify({
    //     text: this.verifyCaptaha,
    //     referenceid: this.captchaRefId,
    //   });

    //   if (this.formData.data.isSocialSignup) {
    //     eventBus.$emit("subscriberSignup", newPayload);
    //   } else {
    //     eventBus.$emit("subscriberLogin", newPayload);
    //   }
    // },

    getAnotherCaptcha() {
      this.getCaptchaImage();
    },

    getCaptchaImage() {
      this.getCaptcha()
        .then((data) => {
          if (data.reason) {
            this.formError = this.$t(data.reason);
            return;
          }

          console.log("CAPCTHA PAYLOAD ---- ", data);

          this.captchaImage = data.payload;
          this.captchaRefId = data.referenceid;
        })
        .catch((error) => {
          console.log("Captcha error -> ", error);
        });
    },
  },

  beforeDestroy() {
    eventBus.$off("social-login-response-captcha-error");
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
@import "./verifyResendCaptcha.scss"
</style>
